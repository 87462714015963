<template>
	<div class="relative flex flex-col gap-2" :style="cssProps">
		<div class="relative my-1">
			<span
				ref="activeRange"
				class="absolute bottom-0 top-0 m-auto h-1 w-[99%] rounded"
			></span>
			<input
				v-model.number="minSelection"
				type="range"
				step="0.01"
				:min="min"
				:max="max"
				:aria-label="`${aria} - Minimum value`"
				class="slider"
			/>
			<input
				v-model.number="maxSelection"
				type="range"
				step="0.01"
				:min="min"
				:max="max"
				:aria-label="`${aria} - Maximum value`"
				class="slider"
			/>
		</div>
		<div class="row flex justify-between text-xs md:text-base">
			<span>{{ minIndicator }}</span>
			<span>{{ maxIndicator }}</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed } from 'vue';
const activeRange = ref<HTMLSpanElement | null>(null);
const minSelectionPercentage = ref(0);
const maxSelectionPercentage = ref(100);

interface Props {
	min: number;
	max: number;
	minSelection: number | null;
	maxSelection: number | null;
	unit: string;
	unitSuffix: boolean;
	fontSize?: string;
	aria: string;
}

const props = defineProps<Props>();

const emit = defineEmits(['changeMinOption', 'changeMaxOption']);

const minSelection = computed({
	get: () => props.minSelection,
	set: (value) => {
		emit('changeMinOption', value);
	},
});

const maxSelection = computed({
	get: () => props.maxSelection,
	set: (value) => {
		emit('changeMaxOption', value);
	},
});

const maxIndicator = computed(() => {
	return props.unitSuffix
		? `${maxSelection.value}${props.unit}`
		: `${props.unit}${maxSelection.value}`;
});

const minIndicator = computed(() => {
	return props.unitSuffix
		? `${minSelection.value}${props.unit}`
		: `${props.unit}${minSelection.value}`;
});

const cssProps = computed(() => {
	return {
		'--font-size': props.fontSize,
	};
});

watchEffect(() => {
	const tempMaxSelection = maxSelection.value;
	if (minSelection.value !== null && maxSelection.value !== null) {
		if (minSelection.value > maxSelection.value) {
			maxSelection.value = minSelection.value;
		} else {
			maxSelection.value = tempMaxSelection;
		}

		minSelectionPercentage.value =
			((minSelection.value - props.min) * 100) / (props.max - props.min);
	}
});

watchEffect(() => {
	const tempMinSelection = minSelection.value;
	if (minSelection.value !== null && maxSelection.value !== null) {
		if (minSelection.value > maxSelection.value) {
			minSelection.value = maxSelection.value;
		} else {
			minSelection.value = tempMinSelection;
		}

		maxSelectionPercentage.value =
			((maxSelection.value - props.min) * 100) / (props.max - props.min);
	}
});

// Set bar color
watch(
	[minSelectionPercentage, maxSelectionPercentage],
	([newMinSelectionPercentage, newMaxSelectionPercentage]) => {
		if (activeRange.value) {
			activeRange.value.style.background = `linear-gradient(to right, #dadae5 ${newMinSelectionPercentage}% , #00664b ${newMinSelectionPercentage}% , #00664b ${newMaxSelectionPercentage}%, #dadae5 ${newMaxSelectionPercentage}%)`;
		}
	},
);

onMounted(() => {
	if (activeRange.value) {
		activeRange.value.style.background = `linear-gradient(to right, #dadae5 0% , #00664b 0% , #00664b 100%, #dadae5 100%)`;
	}
});
</script>
